import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { DisclosedOrder, ID, OrderState, PublicOrder } from '../../Models';
import { app } from '../firebase';
import { patientConverter } from './converters';

const db = getFirestore(app);

export const getPatientData = (order: DisclosedOrder) =>
    (order.customer_id
        ? getDoc(doc(db, 'customers', order.customer_id).withConverter(patientConverter))
        : getDoc(
              doc(db, 'orders', order.id, 'restricted', 'patient').withConverter(patientConverter),
          )
    ).then((patientDocSnap) => {
        // todo pasar a triggers
        if (patientDocSnap.exists()) {
            // eslint-disable-next-line no-param-reassign
            order.patient = patientDocSnap.data();
        }
    });

export const fillPatientData = (orders: DisclosedOrder[]) =>
    Promise.all(orders.map((order) => getPatientData(order)));

export const changeState = (orderId: ID, state: OrderState) =>
    setDoc(
        doc(db, 'orders', orderId), // todo pasar a triggers
        { state },
        { merge: true },
    );

export const toReadyForPickup = (order: PublicOrder) => changeState(order.id, 'ready_for_pickup');
export const toCanceled = (order: PublicOrder) => changeState(order.id, 'canceled');
