import React from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';

export function OrderSection(props: PaperProps) {
    const { children, sx } = props;
    return (
        <Paper elevation={3} sx={{ px: 1.5, py: 1, ...sx }}>
            {children}
        </Paper>
    );
}
