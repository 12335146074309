import React from 'react';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider/Divider';
import Bolt from '@mui/icons-material/Bolt';
import Stack from '@mui/material/Stack';
import { OrderSection } from '../OrderSection';
import { DisclosedOrder, isSchedulePending, PublicOrder } from '../../../../../Models';

const SHORT_ID_TOOLTIP = `ID del pedido.

Este es el código que presentará el cadete cuando llegue a la farmacia.`;

const IMMEDIATE_TOOLTIP = `Este pedido es inmediato. 

Tenés stock en la farmacia para entregar ahora mismo.

En cuanto el paciente abone, el delivery será convocado inmediatamente.
`;
const SCHEDULED_TOOLTIP = `Este pedido está programado. 

Programaste este pedido para ser enviado cuando recibas stock de los medicamentos.

El delivery será convocado automáticamente en esta fecha y horario.
`;
export function ScheduleCard({ order }: { order: PublicOrder | DisclosedOrder }) {
    const isScheduled = !!order.user_offer?.scheduled_stock_datetime;
    let text = '';
    const scheduleDate = dayjs(order.user_offer?.scheduled_stock_datetime);
    if (!isScheduled) {
        text = 'Envío inmediato';
    } else if (scheduleDate.isToday()) {
        text = scheduleDate.format('[Hoy] hh:mm A');
    } else {
        text = scheduleDate.format('dddd DD [de] MMMM hh:mm A');
    }
    return (
        <OrderSection>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Tooltip
                    title={
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            {SHORT_ID_TOOLTIP}
                        </Typography>
                    }
                    enterDelay={200}>
                    <Typography>{`# ${order.short_id}`}</Typography>
                </Tooltip>
                {order.state !== 'emitted' && (
                    <>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip
                            title={
                                <Typography style={{ whiteSpace: 'pre-line' }}>
                                    {isScheduled ? SCHEDULED_TOOLTIP : IMMEDIATE_TOOLTIP}
                                </Typography>
                            }
                            enterDelay={200}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                {isScheduled ? (
                                    <CalendarMonthIcon
                                        color={isSchedulePending(order) ? 'disabled' : 'secondary'}
                                    />
                                ) : (
                                    <Bolt />
                                )}
                                <Typography textAlign="center">{text}</Typography>
                            </Stack>
                        </Tooltip>
                    </>
                )}
            </Stack>
        </OrderSection>
    );
}
