import Box from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useContext } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { UserContext } from '../../services/User';
import { Title } from './Title';
import { HealthProviders } from './HealthProviders';
import { Notifications } from './Notifications';
import { UserProfile } from './UserProfile';
import { Referrals } from './Referrals';
import { MercadoPagoConfig } from './MercadoPagoConfig';

type SectionProps = PaperProps & {
    title: string;
    info?: string;
};
function Section({ title, info, ...rest }: SectionProps) {
    return (
        <Paper sx={{ p: 2 }} {...rest}>
            <Title text={title} info={info} />
            <Divider sx={{ mb: 2 }} />
            {rest.children}
        </Paper>
    );
}
const REFERRAL_URL_TOOLTIP = `Compartí este link de Whatsapp a tus pacientes para que queden asociados a tu farmacia.

⭐ ️Los pacientes asociados siempre tendrán prioridad en tu farmacia y podrás verlos con una estrella.

También podés imprimir tu QR para que los pacientes lo escaneen en el local.`;

export default function Dashboard() {
    const { loading, user } = useContext(UserContext);

    if (loading) {
        return <LinearProgress />;
    }
    return (
        <Box id="dashboard-main-box" sx={{ p: 0, pb: 5 }}>
            <Stack
                direction="column"
                spacing={4}
                justifyContent="space-between"
                alignItems="center">
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ width: '60%' }}>
                    <Section id="user-profile-section" title="Datos de la farmacia">
                        <UserProfile user={user} />
                    </Section>
                    <Section
                        id="mercadopago-section"
                        title="Mercado Pago"
                        info="Recibí los pagos instantáneamente en la cuenta de Mercado Pago de tu farmacia.">
                        <MercadoPagoConfig />
                    </Section>
                    <Section
                        id="health-providers-section"
                        title="Prestadoras habilitadas"
                        info={`Estas son las prestadoras que querés atender.
                            \nEn el panel de pedidos, solo verás pedidos emitidos para estas prestadoras.
                            
                            Dejando este campo vacío, recibirás pedidos por todas las OOSS.
                            `}>
                        <HealthProviders user={user} />
                    </Section>
                    <Section
                        id="referrals-section"
                        title="Referir pacientes"
                        info={REFERRAL_URL_TOOLTIP}>
                        <Referrals user={user} />
                    </Section>
                    <Section
                        id="notifications-section"
                        title="Notificaciones"
                        info="Acá podés configurar el volumen de las notificaciones.">
                        <Notifications />
                    </Section>
                </Stack>
            </Stack>
        </Box>
    );
}
