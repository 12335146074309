import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DisclosedOrder, PublicOrder } from '../../../../../Models';
import OrderStateOptions from './OrderStateOptions';
import { healthProviderTitle } from '../../../../../utils/utils';
import { usePrescriptionData } from '../../../../../services/api/queries/usePrescriptionData';

const hpTitleStyle = {
    p: 0.5,
    borderRadius: 1.5,
    backgroundColor: 'grey.200',
};

type OrderDialogTitleProps = {
    order: PublicOrder | DisclosedOrder;
    closeOrder: () => void;
};
function OrderDialogTitle({ order, closeOrder }: OrderDialogTitleProps) {
    const { otcProducts } = usePrescriptionData(order);

    return (
        <DialogTitle sx={{ pt: 1, pb: 0 }}>
            <Grid2 container spacing={1} alignItems="center">
                <Grid2 xs container alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                        {order.creation_datetime}
                    </Typography>
                </Grid2>
                <Grid2 xs>
                    <Typography variant="h6" align="center" sx={hpTitleStyle}>
                        {healthProviderTitle(order, !!otcProducts.length)}
                    </Typography>
                </Grid2>
                <Grid2 container xs={4} alignItems="center" justifyContent="flex-end">
                    <Grid2>
                        <OrderStateOptions order={order} onStateChange={closeOrder} />
                    </Grid2>
                    <Grid2 p={0}>
                        <IconButton id="close-button" onClick={closeOrder}>
                            <CloseIcon />
                        </IconButton>
                    </Grid2>
                </Grid2>
            </Grid2>
        </DialogTitle>
    );
}

export default OrderDialogTitle;
