import dayjs from 'dayjs';
import { HealthProviderId } from './HealthProvider';
import { ID } from './Firebase';
import { Patient } from './Patient';
import { UserOffer } from './UserOffer';
import { User } from './User';

export type OrderState =
    | 'emitted'
    | 'assigned'
    | 'validated'
    | 'approved'
    | 'ready_for_pickup'
    | 'delivering'
    | 'fulfilled'
    | 'cancellation_requested'
    | 'canceled';

export type HpAuthentication = {
    token: string;
    state: 'none' | 'requested' | 'sent' | 'error';
    last_request_timestamp?: Date;
    last_sent_timestamp?: Date;
    last_error?: string;
    last_error_timestamp?: Date;
};
export type DisclosedOrder = PublicOrder & {
    assigned_pharmacy_id: string;
    patient: Patient;
    hp_authentication?: HpAuthentication;
};
export type PublicOrder = {
    id: ID;
    customer_id: ID;
    short_id: string;
    state: OrderState;
    health_provider: HealthProviderId;
    delivery_zone: string;
    creation_timestamp: Date;
    creation_datetime: string;
    user_offer?: UserOffer;
    bidding_end?: Date;
    bidding_start?: Date;
    referrer_pharmacy_ids: string[];
};

export const isOTC = (order: PublicOrder) => order.health_provider === 'otc';
export const hasOffer = (order: PublicOrder) => !!order.user_offer;
export const isPast = (order: PublicOrder) => order.state === 'fulfilled';
export const requiresApproval = (order: PublicOrder) => {
    const requiresActionList: OrderState[] = ['assigned', 'approved'];
    return (
        (order.state === 'emitted' && !hasOffer(order)) || requiresActionList.includes(order.state)
    );
};
export const requiresAttention = (order: PublicOrder) =>
    requiresApproval(order) || order.state === 'cancellation_requested';
export const inEmittedState = (order: PublicOrder) => order.state === 'emitted';
export const inValidatingState = (order: PublicOrder) => {
    const validatingStatesList: OrderState[] = ['assigned', 'validated'];
    return validatingStatesList.includes(order.state);
};
export const inProcessingState = (order: PublicOrder) => {
    const processingStatesList: OrderState[] = ['approved', 'ready_for_pickup', 'delivering'];
    return processingStatesList.includes(order.state);
};

export const isDisclosedOrder = (x: PublicOrder | DisclosedOrder): x is DisclosedOrder =>
    x.state !== 'emitted';

export const needsHpAuthToken = (order: PublicOrder) => {
    const hpsWithAuthentication: HealthProviderId[] = [
        'osde',
        'swiss_medical',
        'asociacion_mutual_sancor_salud',
        'plus_medical',
    ];
    return order.state === 'assigned' && hpsWithAuthentication.includes(order.health_provider);
};

export const isSchedulePending = (order: PublicOrder | DisclosedOrder) =>
    !!order.user_offer?.scheduled_stock_datetime &&
    order.user_offer?.scheduled_stock_datetime >= new Date();
export const isScheduled = (order: PublicOrder | DisclosedOrder) =>
    !!order.user_offer?.scheduled_stock_datetime;

export const isReferredOrder = (user: User, order: PublicOrder) =>
    order.referrer_pharmacy_ids.includes(user.id);

export const shouldBeShown = (o: PublicOrder, userId: String) => {
    if (!o.referrer_pharmacy_ids.length) {
        // nadie tiene prioridad
        return true;
    }
    // algunos tienen prioridad
    const orderPriority = o.referrer_pharmacy_ids.find((id) => id === userId);
    if (!orderPriority) {
        // en caso de que yo no tenga prioridad, lo mostraremos luego de 10 minutos
        const creationTime = dayjs(o.creation_timestamp);
        const now = dayjs();
        return now.isAfter(creationTime.add(10, 'minutes'));
    }
    return true;
};
