import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { toCanceled, toReadyForPickup } from '../../../../../services/Orders';
import { DisclosedOrder, isSchedulePending, PublicOrder } from '../../../../../Models';
import ConfirmDialog from '../../../../../Components/ConfirmDialog';

interface IndicatorButtonProps {
    message: string;
    onClick?: () => void;
    icon: React.ReactNode;
    color: 'success' | 'error' | 'info' | 'warning';
    disabled?: boolean;
    tooltipTitle: string;
}

function ActionButton({
    message,
    icon,
    color,
    onClick,
    disabled,
    tooltipTitle,
}: IndicatorButtonProps) {
    return (
        <Tooltip
            title={<Typography style={{ whiteSpace: 'pre-line' }}>{tooltipTitle}</Typography>}
            enterDelay={1500}>
            <span>
                <Button
                    variant="contained"
                    color={color}
                    endIcon={icon}
                    disabled={disabled}
                    onClick={onClick}
                    size="small">
                    {message}
                </Button>
            </span>
        </Tooltip>
    );
}
type OrderStateOptionsProps = {
    order: PublicOrder | DisclosedOrder;
    onStateChange: () => void;
};
export default function OrderStateOptions({ order, onStateChange }: OrderStateOptionsProps) {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const onConfirmReadyForPickup = () => {
        toReadyForPickup(order).finally(() => {
            setDialogOpen(false);
            onStateChange();
        });
    };
    const onConfirmCancel = () => {
        toCanceled(order).finally(() => {
            setDialogOpen(false);
            onStateChange();
        });
    };
    if (
        order.state === 'approved' ||
        order.state === 'ready_for_pickup' ||
        order.state === 'delivering'
    ) {
        let disabled = false;
        let tooltipTitle = '';
        if (isSchedulePending(order)) {
            disabled = true;
            tooltipTitle =
                'El botón está deshabilitado porque el pedido está programado para otro momento.\n\nEn cuanto se habilite, apretá este botón para avisar que el pedido ya puede ser retirado.\n\nAl apretar, un repartidor será enviado a buscarlo.';
        } else if (order.state !== 'approved') {
            disabled = true;
            tooltipTitle = 'El delivery ya fue pedido.';
        } else {
            disabled = false;
            tooltipTitle =
                'Apretá este botón para avisar que el pedido ya puede ser retirado.\n\nAl apretar, un repartidor será enviado a buscarlo.';
        }
        return (
            <Box>
                <ActionButton
                    color="success"
                    icon={isSchedulePending(order) ? <CalendarMonthIcon /> : <CheckCircleIcon />}
                    disabled={disabled}
                    onClick={() => setDialogOpen(true)}
                    message="Listo para retirar"
                    tooltipTitle={tooltipTitle}
                />
                <ConfirmDialog
                    title="¿Confirmar?"
                    message="Un delivery será convocado para retirar el pedido."
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onConfirm={onConfirmReadyForPickup}
                />
            </Box>
        );
    }
    if (order.state === 'cancellation_requested') {
        return (
            <Box>
                <ActionButton
                    color="warning"
                    icon={<CancelIcon />}
                    onClick={() => setDialogOpen(true)}
                    message="Confirmar cancelación"
                    disabled={false}
                    tooltipTitle={
                        'Este botón aparece cuando el paciente cancela el pedido.\n\nAl apretar este botón, el pedido desaparecerá de la pantalla, indicando que ya des-facturaste las recetas.'
                    }
                />
                <ConfirmDialog
                    title="¿Desfacturaste la receta?"
                    message="Las recetas facturadas deben ser dadas de baja en el sistema de la financiadora para que el paciente pueda comprar los medicamentos en otra farmacia."
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onConfirm={onConfirmCancel}
                    confirmButtonText="Si"
                    cancelButtonText="No"
                />
            </Box>
        );
    }
    return null;
}
