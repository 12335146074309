import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { toPreviousState } from '../../../../../services/api/orders.endpoints';
import ConfirmDialog from '../../../../../Components/ConfirmDialog';
import { DisclosedOrder, PublicOrder } from '../../../../../Models';

const TO_PREVIOUS_STATE_TOOLTIP = 'Pasar pedido al estado anterior';

const ASSIGNED_CONFIRMATION = `El pedido pasará a la columna de Nuevos.
¿Continuamos?`;
const VALIDATED_CONFIRMATION = `El paciente ya recibió una cotización por estos productos. 
Vas a editar los productos para enviarle una segunda cotización. 

¿Continuamos?`;

function ToPreviousStateButton({ order }: { order: PublicOrder | DisclosedOrder }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const onToPreviousState = () => {
        setLoading(true);
        setOpenDialog(false);
        toPreviousState(order.id).then(() => setLoading(false));
    };
    return (
        <>
            <Tooltip title={TO_PREVIOUS_STATE_TOOLTIP} enterDelay={100} placement="top-end">
                <IconButton tabIndex={-1} onClick={() => setOpenDialog(true)}>
                    {loading ? <CircularProgress size={24} /> : <ArrowBack />}
                </IconButton>
            </Tooltip>
            <ConfirmDialog
                title="☝️Pasar pedido al estado anterior"
                message={
                    order.state === 'assigned' ? ASSIGNED_CONFIRMATION : VALIDATED_CONFIRMATION
                }
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onConfirm={onToPreviousState}
            />
        </>
    );
}

export { ToPreviousStateButton };
