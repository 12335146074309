import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import * as React from 'react';
import { Dayjs } from 'dayjs';

type StyledDatePickerProps = {
    value: Dayjs | undefined;
    onChange: (value: Dayjs | undefined) => void;
    disabled?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
};
const HOURS_MIN = 7;
const HOURS_MAX = 20;
const MINUTES_INTERVAL = 15;
export function StyledDatePicker({
    disabled = false,
    value,
    inputRef,
    onChange,
}: StyledDatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DateTimePicker
                disablePast
                disabled={disabled}
                timeSteps={{ minutes: MINUTES_INTERVAL }}
                shouldDisableTime={(timeValue, view) =>
                    (view === 'hours' && timeValue.hour() < HOURS_MIN) ||
                    timeValue.hour() > HOURS_MAX
                }
                skipDisabled
                sx={{ width: '70%' }}
                slotProps={{
                    textField: { size: 'small' },
                    layout: {
                        sx: {
                            ul: {
                                width: '4.5rem',
                                // scrollbar:
                                '::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.2)',
                                    borderRadius: '10px',
                                    border: '2px solid transparent',
                                    backgroundClip: 'content-box',
                                },
                                '::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: 'rgba(0,0,0,.4)',
                                },
                            },
                            li: {
                                width: '4rem',
                                fontSize: '17px',
                            },
                        },
                    },
                }}
                format={value?.isToday() ? '[hoy] hh:mm A' : 'dddd DD MMM hh:mm A'}
                value={value}
                inputRef={inputRef}
                onChange={(newValue: Dayjs | null) => onChange(newValue || undefined)}
            />
        </LocalizationProvider>
    );
}
