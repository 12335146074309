import { Alert, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Typography from '@mui/material/Typography';
import { rescheduleStock } from '../../../../../services/api/orders.endpoints';
import { DisclosedOrder, PublicOrder } from '../../../../../Models';
import ConfirmDialog from '../../../../../Components/ConfirmDialog';
import { StyledDatePicker } from '../../../../../Components/StyledDatePicker';

const TO_PREVIOUS_STATE_TOOLTIP = `Reprogramar stock.

Podés editar la fecha y hora para la cual todos los productos estarán listo para ser enviados al paciente.`;

type ReScheduleStockProps = {
    order: PublicOrder | DisclosedOrder;
    disabled: boolean;
};

function ReScheduleStock({ order, disabled }: ReScheduleStockProps) {
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [newDate, setNewDate] = useState<Dayjs | undefined>(dayjs());
    const [loading, setLoading] = useState(false);
    const submitReSchedule = () => {
        setLoading(true);
        rescheduleStock(order.id, newDate || dayjs()).then(() => {
            setLoading(false);
            setOpenDialog(false);
            setOpenSnackbar(true);
        });
    };
    return (
        <>
            <Tooltip
                title={
                    <Typography style={{ whiteSpace: 'pre-line' }}>
                        {TO_PREVIOUS_STATE_TOOLTIP}
                    </Typography>
                }
                enterDelay={100}
                placement="top-end">
                <Button
                    tabIndex={-1}
                    variant="text"
                    style={{ color: 'grey' }}
                    onClick={() => setOpenDialog(true)}
                    disabled={disabled || loading}
                    startIcon={loading ? <CircularProgress size={24} /> : <CalendarMonth />}>
                    Reprogramar
                </Button>
            </Tooltip>
            <ConfirmDialog
                title="📆 Reprogramar disponibilidad de stock"
                message="Seleccioná la fecha y hora en que todos los productos estarán listos para la entrega:"
                confirmButtonText="Reprogramar"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onConfirm={submitReSchedule}
                extraContent={<StyledDatePicker value={newDate} onChange={setNewDate} />}
            />
            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                    Pedido reprogramado
                </Alert>
            </Snackbar>
        </>
    );
}

export { ReScheduleStock };
